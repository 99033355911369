


















































import Vue, {PropType} from 'vue';
import { required, email, minLength } from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import { RegisterFilledData } from "@/types";

export default Vue.extend({
  name: "register-user-login" as string,

  props: {
    openModal: {
      type: Boolean as PropType<Boolean>,
      default: false
    },
    userEmail: String as PropType<String>,
    userPassword: String as PropType<String>,
  },

  data() {
    return {
      email: "",
      password: "",
      isSubmitted: false
    }
  },

  //@ts-ignore
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(6) }
  },

  computed: {
    ...mapGetters("userModule", {
      loader: "GET_LOADER"
    }),
    ...mapGetters("emailsModule", {
      isUserEmailVerified: "GET_IS_USER_EMAIL_VERIFIED",
    }),
  },

  methods: {
    ...mapActions('userModule', {
      login: "LOGIN"
    }),
    ...mapActions('registerModule', {
      checkUnfinishedRegistration: "CHECK_UNFINISHED_REGISTRATION",
    }),
    ...mapActions("emailsModule", {
      checkIfIsUserActive: "IS_USER_ACTIVE",
    }),
    async loginUser(): Promise<void> {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      await this.checkIfIsUserActive(this.email);

      let unfinishedRegistrationData = null;

      try {
        unfinishedRegistrationData = await this.checkUnfinishedRegistration(this.email);
      } catch(e) {}

      if (this.isUserEmailVerified === false) {
        router.push("/register/step-4");
      } else if (this.isUserEmailVerified === true && unfinishedRegistrationData) {
        this.tryFinishRegistration(unfinishedRegistrationData);
      } else {

        const payload: object = {
          email: this.email,
          password: this.password,
          firstRegistration: true,
        }

        await this.login(payload);
      }
    },
    async tryFinishRegistration(data: RegisterFilledData): Promise<void> {
      const registerData = {
        "userIsLoggedIn": false,
        "userData": {
            "email": data.email,
            "password": this.password,
            "first_name": data.name,
            "last_name": data.surname,
            "gdpr": true,
            "status": 2,
            "active": this.isUserEmailVerified,
        },
        "companyData": {
            "business_type_id": data.businessTypeId,
            "email": data.email,
        },
        "venueData": {
            "name": data.nameOfEstablishment,
            "address1": data.streetAndNumber,
            "address2": data.postalCode,
            "city": data.city,
            "country_id": data.countryId,
            "latitude": data.gpsLatitude,
            "longitude": data.gpsLongtitude,
            "state": 0,
            "timezone_id": data.timeZoneId,
            "venue_type_id": data.venueTypeId,
        },
        "registrationResourceId": data.id,
        "loggedUser": {}
      };

      localStorage.setItem("registrationData", JSON.stringify(registerData));
      localStorage.setItem("unfinishedRegistration", "true");

      router.push("/register/step-5");
  },
  },

  watch: {
    openModal (newVal) {
      if(newVal) {
        this.$refs.myModalRef.show();
        this.email = this.userEmail;
        this.password = this.userPassword;
      } else {
        this.$refs.myModalRef.hide();
      }
    }
  }
});
