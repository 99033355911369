































import Vue, { PropType } from "vue";
import { mapActions } from "vuex";

export default {
  name: "registration-stepper" as string,
  props: {
    step: Number as PropType<number>,
  },
  data() {
    return {};
  },
  methods: {},
};
