












































































































































































































































































import Vue from "vue";
import Layout from "@/router/layouts/register.vue";
import RegisterUserLogin from "@/components/modals/RegisterUserLogin.vue";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { UserRegisterData } from "@/types";
import i18n from "@/i18n/i18n";
import router from "@/router";
import RegistrationStepper from "@/components/registration-stepper.vue";
import ArrowForward from "@iconify-icons/ion/arrow-forward";
import ArrowBack from "@iconify-icons/ion/arrow-back";

export default Vue.extend({
  components: {
    Layout,
    "register-user-login": RegisterUserLogin,
    RegistrationStepper,
  },

  data() {
    return {
      currentStep: 2,
      email: "",
      password: "",
      //confirmPassword: "",
      firstName: "",
      lastName: "",
      checkboxGdpr: false,
      isSubmitted: false,
      openLoginModal: false,
      saveEmail: "",
      i18: i18n,
      icon: {
        arrowNext: ArrowForward,
        arrowBack: ArrowBack,
      },
      webGeneralTermsUrl: process.env.VUE_APP_SONGOROO_WEB_GENERAL_TERMS_OF_USE,
      webGeneralTermsUrlSk:
        process.env.VUE_APP_SONGOROO_WEB_GENERAL_TERMS_OF_USE_SK,
      webPolicyPrivacyUrl: process.env.VUE_APP_SONGOROO_WEB_PRIVACY_POLICY_LINK,
      webPolicyPrivacyUrlSk:
        process.env.VUE_APP_SONGOROO_WEB_PRIVACY_POLICY_LINK_SK,
      isPasswordVisible: false,
    };
  },

  //@ts-ignore
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
    //confirmPassword: { required, sameAsPassword: sameAs("password") },
    firstName: { required, minLength: minLength(3), maxLength: maxLength(20) },
    lastName: { required, minLength: minLength(3), maxLength: maxLength(20) },
  },

  mounted() {
    this.setSubmitRegisterLoader(false);
    this.track();

    if (this.getUserData) {
      const {email, password, gdpr, first_name, last_name} = this.getUserData;

      this.email = email ?? "";
      this.password = password ?? "";
      this.checkboxGdpr = gdpr ?? false;
      this.firstName = first_name ?? "";
      this.lastName = last_name ?? "";
    }
  },

  computed: {
    ...mapGetters("userModule", {
      getVenueId: "GET_VENUE_ID",
    }),
    ...mapGetters("registerModule", {
      existUserWithEmail: "EXIST_USER_WITH_EMAIL",
      createRegisterLoader: "GET_SUBMIT_REGISTRATION_LOADER",
      getUserData: "GET_USER_DATA",
    }),
    isGdprAccepted(): boolean {
      return this.checkboxGdpr;
    },
    emailExist(): boolean {
      return this.saveEmail === this.email && !!this.email;
    },
  },

  methods: {
    ...mapActions("userModule", {
      saveUser: "SAVE_USER",
    }),
    ...mapActions("registerModule", {
      createUser: "CREATE_NEW_USER",
      setUser: "SET_USER",
      filterUserEmail: "FILTER_USER_EMAIL",
      processClientZoneRegistration: "PROCESS_CLIENTZONE_REGISTRATION",
      setSubmitRegisterLoader: "SET_SUBMIT_REGISTRATION_LOADER",
      saveRegisterFilledData: "SAVE_REGISTER_FILLED_DATA",
    }),
    continueToStepTwo() {
      router.push("/register/step-2?lang=" + this.$i18n.locale);
    },
    async checkIfUserAlreadyExists() {
      this.openLoginModal = false;

      await this.filterUserEmail(this.email);

      if (this.existUserWithEmail) {
        this.saveEmail = this.email;
        this.openLoginModal = true;
      }
    },
    async continueToStepFour(): Promise<void> {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid || !this.isGdprAccepted) return;

      await this.checkIfUserAlreadyExists();

      const userPayload: UserRegisterData = {
        email: this.email,
        password: this.password,
        first_name: this.firstName,
        last_name: this.lastName,
        gdpr: this.checkboxGdpr,
        status: 2,
        active: true,
      };

      this.setUser(userPayload);

      this.setSubmitRegisterLoader(true);

      //await this.processClientZoneRegistration();

      await this.createUser();

      this.setSubmitRegisterLoader(false);

      await this.saveRegisterFilledData();

      if (!this.existUserWithEmail) {
        router.push("/register/step-4");
      }
    },
    track() {
      this.$gtag.event("registration", {
        event_category: "registration",
        event_label: "step",
        value: "3",
      });
    },
    updatePasswordVisibility(): void {
      this.isPasswordVisible = !this.isPasswordVisible;
    }
  },
});
